
import './App.css';
import AOS from 'aos'
import 'aos/dist/aos.css';
import LogoBar from './Components/Logobar';
import FirstSection from './Components/FirstSection';
import SecondSection from './Components/SecondSection';
import ThirdSection from './Components/ThirdSection';
import Contact from './Components/Contact';

function App() {
  AOS.init();
  return (
    <div className="App">
      <LogoBar/>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <Contact />
    </div>
  );
}

export default App;

import { Col, } from 'react-bootstrap';
import './SecondSection.scss';
import house from '../Images/show_house.mp4';

const SecondSection = () => {

    return(
        <section className='second-container'>
            <Col xs={12} md={12} lg={6} data-aos='fade-right' data-aos-offset='100' data-aos-easing='ease-in-cubic' data-aos-duration='500'> 
            <video loop={true} autoPlay muted className='water-video'>
                    <source src={house} type='video/mp4'/>
                </video>
            </Col>
            <Col lg={6} data-aos='fade-left' className='motto' data-aos-offset="100"
            data-aos-easing="ease-in-cubic" data-aos-duration='500'>
                    <p>We transform outdated properties to meet modern standards, providing care, quality and attention to detail.
                    We take pride in our passion and commitment to deliver high quality services to our customers.
                    Our aim is to generate long term value for our customers, partners, our community and our environment.
                    </p>
            </Col>
              
        </section>

    );
};

export default SecondSection;
import dove from '../Images/dove.png';


const LogoBar = () => {
  return (
    <div>
        <img src={dove} alt='dove' className='dove'/>
    </div>
  );
}

export default LogoBar;
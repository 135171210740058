import { Col } from 'react-bootstrap';
import './Contact.scss';

const Contact = () => { 
    return(
        <div>
        <section className='contact'>
            <Col xs={12} lg={12} className='contact-card' data-aos='flip-down' data-aos-offset='200' data-aos-duration='800'>
                <h2>We are always looking to expand our portfolio. If you know a property that would be of interest to us, please
                get in touch.</h2>
                <h3>Email: info@<br/>whitedoveassetlimited.co.uk</h3>
                <h4>White Dove Asset Ltd <br/>
                7 Sefton Chase<br/>
                Crowborough TN6 2TG</h4>
            </Col>
        </section>
        <footer>
            <p>&copy; 2024 White Dove Asset Limited</p>
        </footer>
        </div>

    );
};

export default Contact;
import './ThirdSection.scss';

const ThirdSection = () => {
    return (
        <section className='third-section'>

        </section>
    )


};

export default ThirdSection;
import {Animated} from "react-animated-css";
import './FirstSection.scss';
import scroll from '../Images/scroll-icon.png';
import { Col } from 'react-bootstrap';


const FirstSection = () => {
    
    return(

            <section className="first-container">
                <Col className='overlay'>
                <Animated animationIn='slideInLeft' animationInDelay={1000} className='main-title name'>
                <div>
                    <p>White Dove Asset Limited</p>
                </div>
                <Animated animationIn='slideInLeft' animationInDelay={1500}>
                <div>
        
                    <h2>Property Developer</h2>
                </div>
                <Animated animationIn='bounceInDown' animationInDelay={2000} className='scroll-container'>
                <div>
                <img src={scroll} alt='scroll icon' className='scroll'/>
                </div>
                </Animated>
                </Animated>
                </Animated>
                </Col>
        
            </section>
    );

};

export default FirstSection;